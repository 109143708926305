import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Markdown from "../components/Markdown";

import App from "../components/App";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

import LinkedInIcon from "../assets/images/icon-linkedin.svg";
import TwitterIcon from "../assets/images/icon-twitter.svg";
import FacebookIcon from "../assets/images/icon-facebook.svg";

class BlogPost extends Component {
  constructor(props) {
    super(props);
		this.state = {
			url: null,
			email: '',
			btnMessage: 'Submit',
    }
	}

	onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

	componentDidMount() {
		this.setState({ url: window.location.href });
	}

  render() {

		const submit = e => {
			e.preventDefault()
			const email = this.state.email;
			console.log(email);
			fetch(`https://hooks.zapier.com/hooks/catch/1749305/or0p3se/`, {
				method: 'POST',
				body: JSON.stringify({ email, formKey: 'blog-signup' }),
			}).then(() => {
				document.getElementById('submitBtn').disabled = true;
				this.setState({btnMessage: 'Thank you!'});
			}).catch((err) => {
				console.error(err)
			})
		}

		const validateEmail = (email) => {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

		const isDisabled = () => {
      if (validateEmail(this.state.email))  {
        return false
      } else {
        return true
      }
    }

    const { title, content, excerpt, image, author, createdAt } = this.props.data.contentfulBlogPost;
    const navbarData = this.props.data.allContentfulNavbar.edges[0].node;
    const footerData = this.props.data.allContentfulFooter.edges[0].node;

		const postImageStyle = {
			backgroundImage: `url(${image.file.url})`,
		};

		const authorImageStyle = {
			backgroundImage: `url(${author.photo.file.url})`,
		};

    return (
      <App navbar={navbarData} >

				<div className="hero is-post">
					<div className="hero-overlay"></div>

					<Container fluid className="hero-nav">
						<Navigation menus={navbarData.menus} />
					</Container>
				</div>

				<div className="content post-content pb-lg">
					<Container>
						<div className="post-image" style={postImageStyle}></div>
					</Container>
					<Container className="content-section pt-md pb-lg">
						<Row>
							<Col lg="8">
								<div className="post-header">
					        <h1>{title}</h1>
									<h6 className="text-primary">{createdAt}</h6>
								</div>
								<hr className="post-divider" />
								<div className="post-body">
									<Markdown content={content} />
								</div>
							</Col>
							<Col lg="4">
								<div className="sidebar post-sidebar">
									<Card className="sidebar-signup">
										<Card.Body>
											<h4>Get more articles</h4>
											<Form>
												<Form.Group>
													<Form.Label className="sr-only">Email address</Form.Label>
													<Form.Control type="email" placeholder="Email address" name="email" value={this.state.email} onChange={this.onChange}  />
												</Form.Group>
												<Button id="submitBtn" variant="primary" onClick={submit} disabled={isDisabled()} type="submit" block>
											    {this.state.btnMessage}
											  </Button>
											</Form>
										</Card.Body>
									</Card>
									<Card className="sidebar-author">
										<Card.Body>
											<div className="author-photo" style={authorImageStyle}></div>
											<div className="author-details">
												<h5>{author.name}</h5>
												<p>{author.shortBio}</p>
											</div>
										</Card.Body>
									</Card>
									<Card className="sidebar-share">
										<Card.Body>
											<h5>Share article</h5>
											<ul className="social">
												<li><a href={`https://www.linkedin.com/sharing/share-offsite/?url=${this.state.url}`}><img src={LinkedInIcon} alt="LinkedIn" /></a></li>
												<li><a href={`https://twitter.com/intent/tweet?text=${title}&url=${this.state.url}`}><img src={TwitterIcon} alt="Twitter" /></a></li>
												<li><a href={`https://www.facebook.com/sharer/sharer.php?u=${this.state.url}`}><img src={FacebookIcon} alt="Facebook" /></a></li>
											</ul>
										</Card.Body>
									</Card>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
        <Footer footer={footerData}/>
      </App>
    )
  }
}

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogPost

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
			excerpt
      content {
        json
			}
			image {
				file {
					url
				}
			}
			author {
				photo {
					file {
						url
					}
				}
				name
				shortBio
			}
			createdAt(formatString: "MMMM D, YYYY")
    }
    allContentfulNavbar {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
          colophon
        }
      }
    }
  }
`
